import React, { useEffect, useState } from "react";
import axiosClient from "../axios-client";
import { NavLink } from "react-router-dom";


const All_Blogs = (props) => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    axiosClient.get('/user/blogs')
      .then((response) => {
        const data = response.data;
        const dataArray = Array.isArray(data) ? data : [];
        setBlogs(dataArray);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const metaDescription = (data) => {
    // const sanitizedContent = DOMPurify.sanitize(data);
    // const truncatedContent = sanitizedContent.substring(0, 20);
    // return <div dangerouslySetInnerHTML={{ __html: truncatedContent }} />;


    const words = data.split(' ');
    const truncatedWords = words.slice(0, 15);
    const truncatedContent = truncatedWords.join(' ');

    return <p>{truncatedContent}</p>
   
  };

  return (
    <div className="container">
      <div className="row">
        {blogs.map((blog) => (
          <div className="col-md-4 mb-4" key={blog.id}>
            <div className="card">
              <img
                className="card-img-top"
                alt={blog.title}
                style={{ borderRadius: "10px 10px 0px 0px" }}
                src={`http://api.tourhoot.com/storage/app/public/${blog.featured_image}`}
              />
              <div className="card-body">
                <h5 className="card-title">
                  <b>{blog.title}</b>
                </h5>
                {metaDescription(blog.meta_description)}
                <NavLink
                  style={{ textDecoration: "none" }}
                  to={`../Blog/${blog.id}`}
                >
                  See more...
                </NavLink>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default All_Blogs;
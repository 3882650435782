import React, { useState } from "react";
import { FaFacebook } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import "./page_style.css";
import axiosClient from "../axios-client";
import { useStateContext } from "../contexts/ContextProvider";

const LoginSignup = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLogin, setIsLogin] = useState(true);
  const { setUser, setToken } = useStateContext();
  const [errors, setErrors] = useState(null);
  const [companyName, setCompanyName] = useState("");

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    const payload = {
      email: email,
      password: password,
    };

    axiosClient
      .post("/login", payload)
      .then(({ data }) => {
        setUser(data.user);
        setToken(data.token);
      })
      .catch((err) => {
        const response = err.response;
        if (response && response.status === 422) {
          if (response.data.errors) {
            setErrors(response.data.errors);
          } else {
            setErrors({
              email: [response.data.message],
            });
          }
        } else {
          setErrors("User name or password is incorrect");
        }
      });
  };

  const handleSignupSubmit = (e) => {
    e.preventDefault();

    const payload = {
      name: name,
      email: email,
      phone: phone,
      password: password,
      password_confirmation: confirmPassword,
      company_name: companyName,
    };
    console.log(payload);

    axiosClient
      .post("/signup", payload)
      .then(({ data }) => {
        setUser(data.user);
        setToken(data.token);
      })
      .catch((err) => {
        const response = err.response;
        if (response && response.status === 422) {
          setErrors(response.data.errors);
        }
      });
  };

  const toggleLoginSignup = () => {
    setIsLogin(!isLogin);
  };

  return (
    <div className="login-signup-container">
      <div
        className={`form-container ${isLogin ? "" : "form-container-expanded"}`}
      >
        <h2>{isLogin ? "Login" : "Create New Account"}</h2>
        <form onSubmit={isLogin ? handleLoginSubmit : handleSignupSubmit}>
          {!isLogin && (
            <input
              type="text"
              placeholder="Name"
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
            />
          )}
          <input
            type="email"
            placeholder="Email"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
          />
          {!isLogin && (
            <input
              type="tel"
              placeholder="Phone Number"
              value={phone}
              required
              onChange={(e) => setPhone(e.target.value)}
            />
          )}
          {!isLogin && (
            <input
              type="text"
              placeholder="Company Name"
              value={companyName}
              required
              onChange={(e) => setCompanyName(e.target.value)}
            />
          )}

          <input
            type="password"
            placeholder="Password"
            value={password}
            required
            onChange={(e) => setPassword(e.target.value)}
          />
          {!isLogin && (
            <input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              required
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          )}
          {errors && (
            <p style={{ color: "red" }}>
            {errors}
            </p>
          )}
          <button type="submit">{isLogin ? "Login" : "Create Account"}</button>
        </form>
        <div className="toggle-link" onClick={toggleLoginSignup}>
          {isLogin ? "Create New Account" : "Already have an account? Login"}
        </div>
        {/* <div className="social-login">
          <p>Or login with:</p>
          <div className="social-icons">
            <FaFacebook
              style={{ color: "#15A0F9" }}
              className="facebook-icon"
              onClick={() => console.log("Facebook Login")}
            />
            <FcGoogle
              className="google-icon"
              onClick={() => console.log("Google Login")}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default LoginSignup;

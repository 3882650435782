import React, { useEffect, useState } from "react";
import axiosClient from "../axios-client";
import { useParams } from "react-router-dom";
import RenderHTMLContent from "./RenderHTMLContent";


const Blog = (props) => {
  const [blog, setBlog] = useState([]);
  let pid = useParams();
  const { id } = pid;
  useEffect(() => {
    // Fetch data from your API when the component mounts
    axiosClient
      .get(`/blog/${id}`) // Fetch the specific tour data
      .then((response) => {
        setBlog(response.data); // Update formData with fetched data
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleDelete = (event) => {
    event.preventDefault();

    axiosClient
      .delete(`/deleteBlog/${id}`) // Replace with your delete API endpoint
      .then(() => {
        alert("Blog deleted successfully");
        // Navigate("/OperatorDashboard"); // Redirect to a specific page after successful deletion
        window.location.href = "/Blogs";
      })
      .catch((err) => {
        const response = err.response;
        if (response && response.status === 422) {
          console.log(response.data.errors);
        } else {
          console.log(response.data.errors);
        }
      });
  };

  const renderContent = (content) => {
    try {
      const parsedContent = JSON.parse(content);
      if (
        parsedContent &&
        parsedContent.blocks &&
        Array.isArray(parsedContent.blocks) &&
        parsedContent.blocks.length > 0
      ) {
        return parsedContent.blocks.map((block, index) => (
          <p key={index}>
            {block.text.split(" ").map((word, wordIndex, wordsArray) => {
              const isLastWord = wordIndex === wordsArray.length - 1;
              const wordWithSpace = isLastWord ? word : word + " ";

              // Check if any inline style is specified for the current word
              const style = block.inlineStyleRanges.find(
                (range) =>
                  block.text.indexOf(word) >= range.offset &&
                  block.text.indexOf(word) + word.length <=
                    range.offset + range.length
              );
              if (style) {
                // Apply inline style if present
                return (
                  <span
                    key={wordIndex}
                    style={{
                      fontWeight: style.style === "BOLD" ? "bold" : "normal",
                      fontStyle: style.style === "ITALIC" ? "italic" : "normal",
                    marginLeft:0}}
                  >
                    {wordWithSpace}
                  </span>
                );
              } else {
                // Render plain text for words without inline styles
                return wordWithSpace;
              }
            })}
          </p>
        ));
      } else {
        console.warn(
          "No content available or unexpected data structure.",
          parsedContent
        );
        return <p>No content available</p>;
      }
    } catch (error) {
      console.error("Error parsing content:", error);
      return <p>Error parsing content</p>;
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12" key={blog.id}>
          <div className="card" >
            {/* <img src={blog.featured_image} className="card-img-top" alt={blog.title} /> */}
            <div style={{ textAlign: "center" }}>
              <img
                className="card-img-top"
                alt={blog.title}
                style={{
                  borderRadius: "10px 10px 0px 0px",
                  height: "50vh",
                  width: "auto",
                }}
                // src={`http://api.tourhoot.com/storage/app/public/images/${
                src={`http://api.tourhoot.com/storage/app/public/${blog.featured_image}`}
              />
            </div>
            <div className="card-body">
              <h5 className="card-title" style={{ textAlign: "center" }}>
                <b> {blog.title} </b>
              </h5>

              <RenderHTMLContent htmlContent={blog.content} /> 
              {/* {renderContent(blog.content)} */}
              {/* {blog.content} */}

              <br />
              <button
                className="btn btn-primary"
                style={{
                  backgroundColor: "rgb(213 0 28)",
                  borderColor: "red",
                }}
                onClick={handleDelete}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;

// Import necessary dependencies
import React, { useEffect, useState }from "react";
import { BrowserRouter, Route, Routes} from "react-router-dom";
import Navbar from "./components/Navbar";
import Bottom_Navbar from "./components/Bottom_Navbar";
import Footer from "./components/Footer";

// Import your page components
import Home from "./pages/Home";
import Profile from "./pages/Profile";

import Support from "./pages/Support";

import Login from "./pages/Login";

import NotFound from "./components/NotFound";

import GuestLayout from "./components/GuestLayout";
import axiosClient from "./axios-client";
import Dashboard from "./components/Dashboard";
import TourOperatorLayout from "./components/TourOperatorLayout";
import Add_Blog from "./components/Add_Blog";
import All_Blogs from "./components/All_Blogs";
import Blog from "./components/Blog";

function App() {

  

  const routesConfig = [
   
  
    {
      path: "/", // Root path
      element: <GuestLayout />,
      children: [
        {
          path: "/", // Child paths start with "/" to indicate relative paths
          element: <Login />,
        },
        {
          path: "Login", // Relative path
          element: <Login />,
        },
       
       
      ],
    },
    {
      
      path: "/", // Root path
      element: <TourOperatorLayout />,
      children: [
        
        {
          path: "OperatorDashboard", // Relative path
          element: <Dashboard />,
        },
        {
          path: "Profile", // Relative path
          element: <Profile />,
        },
        {
          path: "Add_Blog", // Relative path
          element: <Add_Blog />,
        },
        {
          path: "Blogs", // Relative path
          element: <All_Blogs />,
        },
        {
          path: "Blog/:id", // Relative path
          element: <Blog />,
        },
       
      ],
    },
  
   
    {
      path: "*", // Catch-all route
      element: <NotFound />,
    },
  ];
  

  return (
    <BrowserRouter>
    <div style={{minHeight:"calc(100vh - 98px)"}}>
    <Navbar />
      <Routes>
        {routesConfig.map((route, index) => (
          <Route key={index} path={route.path} element={route.element}>
            {route.children && route.children.map((childRoute, childIndex) => (
              <Route
                key={childIndex}
                path={childRoute.path}
                element={childRoute.element}
              />
            ))}
          </Route>
        ))}
      </Routes>
    </div>
   
      <Bottom_Navbar />
      <Footer />
    </BrowserRouter>
  );
}

export default App;

import React from 'react';
import {  CDBBox, CDBBtn } from 'cdbreact';
import {CgFacebook} from 'react-icons/cg'
import {BsTwitter} from 'react-icons/bs'
import {FaInstagram} from 'react-icons/fa'
import "./style.css";
function Footer  () {
  return (
    <>
    <footer className="shadow">
       <CDBBox
        display="flex"
        justifyContent="between"
        alignItems="center"
        className=" mx-auto py-4 flex-wrap"
        style={{ width: '80%' }}
      >
        <CDBBox display="flex"  className='mx-auto-custom' alignItems="center">
          <a style={{textDecoration:"none"}} href="/" className="d-flex align-items-center p-0 text-dark">
            <img
              alt="logo"
              src="/Images/logo.png"
              width="50px"
            />
            <span className="h5 mb-0 font-weight-bold " style={{marginLeft:"2px"}}>Tour Hoot</span>
          </a>
        </CDBBox>
        <CDBBox  className='mx-auto-custom'>
          <p className="ms-2" style={{textAlign:"center"}}>&copy; Tour Hoot, 2024. All rights reserved.</p>
        </CDBBox>
        <CDBBox className='mx-auto-custom' display="flex">
          <CDBBtn flat color="dark" className="p-2">
            <CgFacebook />
          </CDBBtn>
          <CDBBtn flat color="dark" className="mx-3 p-2">
            <BsTwitter />
          </CDBBtn>
          <CDBBtn flat color="dark" className="p-2">
            <FaInstagram />
          </CDBBtn>
        </CDBBox>
      </CDBBox>
    </footer>
    </>
  );

};

export default Footer;
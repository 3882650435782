import React from "react";
import { NavLink } from "react-router-dom"; // Make sure to import NavLink from 'react-router-dom'
import "./style.css";
import { AiOutlineHome } from 'react-icons/ai';
import { CgProfile } from 'react-icons/cg';

import {
 
  faHome,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";



const tabs = [
  {
    route: "/",
    icon: faHome,
    label: "Home",
  },
  {
    route: "/search",
    icon: faHome,
    label: "New",
  },
  {
    route: "/login",
    icon: faUserCircle,
    label: "Login",
  },
];

const Bottom_Navbar = () => {
  return (
    <nav className="navbar fixed-bottom navbar-light d-lg-none" role="navigation">
      <nav className="w-100">
        <div className=" d-flex flex-row justify-content-around w-100">
          {
            <>
            <NavLink
              to="/"
              className="nav-link active"
              activeClassName="active"
            >
              <div className="row d-flex flex-column justify-content-center align-items-center">
                <AiOutlineHome style={{height:"27px"}}/>
                <div>Home</div>
              </div>
            </NavLink>
            <NavLink
              to="/CreateTour"
              className="nav-link"
              activeClassName="active"
            >
              <div className="row d-flex flex-column justify-content-center align-items-center">
             
               <img style={{ marginTop:"-30px",height:"60px"}} src="/Images/botoom_logo.png" alt="Create_Tour" />
                <div style={{textAlign:"center"}} >New</div>
              </div>
            </NavLink>
            <NavLink
              to="/profile"
              className="nav-link"
              activeClassName="active"
            >
              <div className="row d-flex flex-column justify-content-center align-items-center">
              
                <CgProfile style={{height:"27px"}}/>
                <div>Profile</div>
              </div>
            </NavLink>
            </>
           
          }
        </div>
      </nav>
    </nav>
  );
};

export default Bottom_Navbar;

import React, { useEffect, useState } from "react";
import JoditEditor from "jodit-react";
import { useRef, useMemo } from "react";

import slugify from "slugify"; // Import slugify library
import axiosClient from "../axios-client";
import { useStateContext } from "../contexts/ContextProvider";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.css";

const Add_Blog = () => {
  const editor = useRef(null);

  const [content, setContent] = useState("");

  const { user, setUser } = useStateContext();

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    axiosClient
      .get("/user")
      .then(({ data }) => {
        setUser(data);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);

  const [formData, setFormData] = useState({
    title: "",
    author_id: user ? user.id : "", // Assuming you have a way to determine the author ID
    published_at: new Date().toISOString(), // Current date and time
    slug: "", // Slug will be generated based on the title
    featured_image: null, // For uploading featured image
    status: "published", // Default status is 'draft'
    meta_description: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // If the title changes, generate a new slug
    if (name === "title") {
      const slug = slugify(value, { lower: true }); // Generate slug from title
      setFormData({ ...formData, [name]: value, slug }); // Update title and slug
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, featured_image: file, author_id: user.id });
  };

  // const onEditorStateChange = (editorState) => {
  //   setEditorState(editorState);
  //   const contentState = editorState.getCurrentContent();
  //   const content = JSON.stringify(convertToRaw(contentState));
  //   console.log("Content:", content);
  //   setFormData({ ...formData, content });
  // };

  const onEditorStateChange = (newContent) => {
    setContent(newContent);
    console.log("Updated Content:", content);
    setFormData({ ...formData, content });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedFormData = { ...formData, author_id: user.id };

    // Update formData with the author_id
    setFormData(updatedFormData);

      // console.log("Updated form data with author_id:", updatedFormData);

      // console.log("Submitted form data:", formData);
    try {
      const formDataToSend = new FormData();
      for (const key in updatedFormData) {
        formDataToSend.append(key, updatedFormData[key]);
      }

      // console.log("FormData to Send:");
      // for (let pair of formDataToSend.entries()) {
      //   console.log(pair[0] + ', ' + pair[1]);
      // }

      await axiosClient.post("/createBlogs", formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      alert("Blog post published successfully!");
      window.location.href = "/";
    } catch (error) {
      // console.error("Error publishing blog post:", error);
      // alert("Error publishing blog post:", error.message);

      console.error("Error publishing blog post:", error);
      let errorMessage = "Error publishing blog post:";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage += " " + error.response.data.message;
      }
      alert(errorMessage);
    }
  };

  return (
    <div className="container mt-5" style={{ marginBottom: "7rem" }}>
      <div className="card shadow">
        <div className="card-body">
          <h2 style={{ textAlign: "center" }}>Publish Blog Post</h2>
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="mb-3">
              <label htmlFor="title" className="form-label">
                Title:
              </label>
            
              <input
                type="text"
                className="form-control"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                required
              />
            </div>
           
            <div className="mb-3">
              <label className="form-label">Content:</label>

              <JoditEditor
                ref={editor}
                value={content}
                onChange={onEditorStateChange}
              
                required
              />
              {/* <Editor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class form-control" // Added form-control class
                toolbarClassName="toolbar-class"
                required
              /> */}

            </div>
            <div className="mb-3">
              <label htmlFor="featured_image" className="form-label">
                Featured Image:
              </label>
              <input
                type="file"
                className="form-control"
                id="featured_image"
                name="featured_image"
                onChange={handleFileChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="meta_description" className="form-label">
                Meta Description:
              </label>
              <textarea
                className="form-control"
                id="meta_description"
                name="meta_description"
                value={formData.meta_description}
                onChange={handleInputChange}
              />
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="btn btn-primary"
                style={{ backgroundColor: "#2B8963" }}
              >
                Publish Now
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Add_Blog;
